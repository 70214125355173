@import url('https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap');

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Urbanist';
  color: rgb(148 163 184/1);
  background-color: #efefef;
  font-weight: 500;
  overflow: auto !important;
}

.bg-primary-v2 {
  background-color: #100D28;
}

.btn-primary-v2 {
  background-color: #100D28;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
}

.btn-primary-v2:hover,
.btn-primary-v2:active,
.btn-primary-v2:focus {
  background-color: #C484F1 !important;
  color: #fff !important;
}

.btn-custom {
  background-color: #C484F1;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
}

.btn-custom:hover,
.btn-custom:active,
.btn-custom:focus {
  background-color: #b352f8 !important;
  color: #fff !important;
}

.btn-secondary-v2 {
  background-color: #44A3FD;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
}

.btn-secondary-v3 {
  background-color: #fff;
  color: #44A3FD;
  border: 1px solid #44A3FD;
  font-size: 16px;
  font-weight: 500;
}

.btn-secondary-v3:hover {
  background-color: #efefef;
  color: #44A3FD;
  border: 1px solid #44A3FD;
}

.btn-secondary-v3 a {
  color: #44A3FD;
  text-decoration: none;
}

.icon-secondary-v2 {
  color: #44A3FD;
  font-size: 56px;
  background-color: #44a4fd52;
  width: 90px;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.btn-secondary-v2:hover,
.btn-secondary-v2:active,
.btn-secondary-v2:focus {
  background-color: #0485ff !important;
  color: #fff !important;
}

.border-bottom-gray {
  border-bottom: 1px solid rgb(40 40 40);
}

.fw-600 {
  font-weight: 600;
}

.text-primary-v2 {
  color: rgb(148 163 184/1) !important;
}

/* START HEADER */

.navbar-links a {
  text-decoration: none;
  color: inherit !important;
  margin-right: 30px;
  transition: all .3s ease;
  letter-spacing: .3px;
}

.navbar-links a:hover {
  color: #C484F1 !important;
}

.wp-icon {
  color: #22b258;
}

.navbar-end .dropdown-menu {
  width: 230px !important;
}

.navbar-end .dropdown-menu button {
  font-size: 15px;
  font-weight: 600;
}

.avatar-small {
  background-color: #2b274e;
  width: 35px;
  height: 35px;
  border-radius: 50%;
}

.avatar-small span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-size: 15px;
  letter-spacing: 2px;
}

.navbar-end .dropdown-toggle::after {
  display: none;
}

.navbar-links a.link-active {
  color: #C484F1 !important;
}

.mobile-header a.link-active {
  background-color: #C484F1 !important;
  border-radius: 5px;
  color: #fff !important;
}

/* END HEADER */

/* START WELCOME */

.welcome-content {
  background-color: #fff;
}

.avatar {
  background-color: #100D28;
  width: 75px;
  height: 75px;
  border-radius: 50%;
}

.avatar span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-size: 32px;
  letter-spacing: 2px;
}

.welcome-title {
  font-weight: 600;
  letter-spacing: .5px;
  color: #100D28;
  font-size: 30px;
}

/* END WELCOME */

/* START CARD */

.card-wrap {
  background: #fff;
  border-radius: 20px;
  border: 10px solid #fff;
  overflow: hidden;
}

.card-header {
  border-radius: 50% !important;
  width: 120px;
  height: 120px;
  background: linear-gradient(300deg, #44A3FD 0%, #C484F1 100%);
}

.card-header i {
  color: #fff;
  font-size: 40px;
}

.card-content {
  text-align: center;
}

.card-title {
  font-size: 17px;
  color: #100D28;
}

.card-text {
  text-align: center;
  font-size: 20px;
  color: #100D28;
  font-weight: 600;
}

/* END CARD */

/* START UPLOAD CARD */

.drop-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 275px;
  border-radius: 10px;
  border: 2px dashed #a7a7a7;
  cursor: pointer;
  transition: all .2s ease-in-out, border .2s ease-in-out;
}

.drop-container:hover,
.drop-container.drag-active {
  background: #f8f8f8;
  border-color: #7c7c7c;
}

.drop-container:hover .drop-title,
.drop-container.drag-active .drop-title {
  color: #363636;
}

.drop-title {
  color: #666;
  font-size: 16px;
  transition: color .2s ease-in-out;
}

input[type=file] {
  width: 350px;
  max-width: 100%;
  color: #444;
  padding: 5px;
  background: #fff;
  border-radius: 10px;
  border: 1px solid #555;
}

input[type=file]::file-selector-button {
  margin-right: 20px;
  border: none;
  background: #084cdf;
  padding: 10px 20px;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
  transition: all .2s ease-in-out;
}

input[type=file]::file-selector-button:hover {
  background: #0d45a5;
}

.upload-card-title {
  font-size: 24px;
  font-weight: 600;
}

.file-input-badge {
  border: 1px solid #dadada;
  border-radius: 5px;
  background-color: #efefef;
}

.badge.btn-outline-danger:hover {
  color: #fff !important;
}

.file-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* END UPLOAD CARD */

/* START WITHDRAWAL */

.nav-pills {
  width: 500px;
}

.nav-item.wallet-nav-item {
  width: 50%;
}

.nav-pills .nav-link {
  text-align: center;
  background: #100D28;
  color: #fff;
  border-radius: 10px;
}

.nav-pills .nav-link:hover {
  background: #201952;
}

.nav-pills .nav-link.active {
  background: #fff;
  color: #000;
  border: 1px solid #dadada;
}

.tab-content.wallet-tab-content {
  background: #fff;
  color: #000;
  padding: 40px 80px;
  margin-top: 100px;
}

.tab-content .form-control,
.tab-content .form-select {
  padding: .5rem .75rem !important;
}

#amountCrypto {
  padding-left: 55px !important;
}

.tab-content label {
  font-size: 15px;
  font-weight: 500;
}

.amount-badge {
  position: absolute;
  right: 12px;
  bottom: 0;
  border-radius: 0 .375rem .375rem 0;
  background-color: #e7e7e7;
  height: 41px;
  width: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: .7px;
}

.amount-crypto-badge {
  position: absolute;
  left: 12px;
  bottom: 0;
  border-radius: .375rem 0 0 .375rem;
  background-color: #e7e7e7;
  height: 41px;
  width: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: .7px;
}

.withdraw-navs.nav-tabs .nav-link.active {
  font-size: 18px;
  font-weight: 600;
  color: #100D28;
}

.withdraw-navs.nav-tabs .nav-link {
  color: #aaa;
}

.withdraw-navs.nav-tabs .nav-link:hover {
  border: 1px solid #fff;
}

/* END WITHDRAWAL */

/* START DEPOSIT */

.deposit-crypto-card {
  background-color: #f5f5f5;
}

.bank-logos {
  background-color: #f5f5f5;
  padding: 20px 20px;
  border-radius: 0 0 7px 7px;
}

.deposit-crypto-title {
  color: #666666;
  font-size: 15px;
}

.qr-box {
  background-color: #e7e7e7;
  border-radius: 0 0 5px 5px;
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
}

/* END DEPOSIT */

@media (max-width: 992px) {
  .signin-form {
    width: 100% !important;
  }

  .navbar-links,
  .navbar-end,
  .welcome-change-password {
    display: none !important;
  }

  .welcome-title {
    font-size: 24px;
  }

  .nav-pills {
    width: 315px !important;
  }

  .tab-content.wallet-tab-content {
    padding: 30px 5px 5px 5px !important;
  }

  .documents-warning {
    margin: 5px 15px;
  }

  .avatar {
    width: 65px;
    height: 65px;
    border-radius: 50%;
  }

  .avatar span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 65px;
    height: 65px;
    font-size: 30px;
    letter-spacing: 2px;
  }

  .welcome-title {
    font-weight: 600;
    letter-spacing: .5px;
    color: #100D28;
    font-size: 24px;
  }

  .mobile-navbar-links a {
    font-size: 20px;
    text-decoration: none;
    color: #d9d9d9;
    font-weight: 600;
    /* background-color: rgba(223,231,241,.1); */
    padding: 17px 20px;
  }

  .mobile-header .offcanvas {
    background-color: #100D28;
  }

  .mobile-header .btn-close-custom {
    background-color: transparent;
    border: none;
    margin-left: auto;
  }

  .market-links {
    display: none !important;
  }

  .mb-sm-11 {
    margin-bottom: 12rem !important;
  }

  .render-bank-images img {
    width: 100% !important;
  }

  .two-factor-card {
    padding: 20px !important;
  }
}

@media (min-width: 993px) {
  .mobile-header {
    display: none !important;
  }
}

.CryptoPanicWidget-header,
.CryptoPanicWidget-news-item-link {
  text-decoration: none;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1245px;
  }
}

@media (min-width: 1400px) {
  .container {
    max-width: 1320px;
  }
}

/* SignIn */

.signin-title {
  font-weight: 600;
  font-size: 32px;
  color: #232c44;
}

.signin-form label {
  font-size: 15px;
  color: #6a6c72;
}

.signin-form .signin-btn {
  border: 0;
  background-color: #4f46e5;
  color: #fff;
  padding: 12px;
  font-weight: 500;
  font-size: 15px;
  letter-spacing: .4px;
}

.signin-form i {
  color: #96989d;
}

.signin-form .show-hide-btn {
  position: absolute;
  right: 3px;
  top: 3px;
  z-index: 12;
}

.signin-form .show-hide-btn:active,
.signin-form .show-hide-btn:focus {
  border: none;
}

.signin-btn.disabled {
  pointer-events: none;
  background-color: rgba(79, 70, 229, .6);
}

.forgot-pass-text {
  color: inherit;
}

.market-iframe {
  height: calc(100vh - 123px);
}

.username-container {
  width: 132px;
}

.bank-logos img {
  /* filter: grayscale(1); */
}

.mb-lg-8 {
  margin-bottom: 5rem;
}

/* SWITCH BUTTON */
#checkboxInput {
  display: none;
}

.toggleSwitch {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 30px;
  height: 18px;
  background-color: rgb(209 209 209);
  border-radius: 20px;
  cursor: pointer;
  transition-duration: .2s;
}

.toggleSwitch::after {
  content: "";
  position: absolute;
  height: 10px;
  width: 10px;
  left: 5px;
  background-color: transparent;
  border-radius: 50%;
  transition-duration: .2s;
  box-shadow: 5px 2px 7px rgba(8, 8, 8, 0.26);
  border: 5px solid white;
}

#checkboxInput:checked+.toggleSwitch::after {
  transform: translateX(100%);
  transition-duration: .2s;
  background-color: white;
}

/* Switch background change */
#checkboxInput:checked+.toggleSwitch {
  background-color: #579EFB !important;
  transition-duration: .2s;
}

/* 2FA */

@media (max-width: 1199px) {
  .twofa-card {
    width: 90% !important;
  }
}

@media (max-width: 600px) {
  .twofa-card {
    width: 95% !important;
    margin: 25px auto !important;
  }

  .twofa-input {
    width: 30px !important;
    height: 40px !important;
    font-size: 24px !important
  }
}

.twofa-card {
  color: #000;
  padding: 30px;
  border-radius: 5px;
  width: 50%;
  margin: 75px auto;
  background-color: #ffffff;
  border-radius: 5px;
}

.twofa-input {
  width: 50px;
  height: 50px;
  text-align: center;
  font-size: 28px;
  font-weight: 500;
  border: none;
  border-bottom: 2px solid #ccc;
  background-color: transparent;
  outline: none;
}

.twofa-input:focus {
  border-bottom: 2px solid #100D28;
}

.two-factor-card {
  background-color: #fff;
  padding: 50px;
  color: #000;
}

.icon-container {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* END 2FA */

.header-bottom {
  background-color: #100D28;
}

@media (min-width: 1200px) and (max-width: 1281px) {
  .market-links img {
    width: 100px !important;
  }
}

@media screen and (max-width: 1200px) {
  .market-links {
    display: none !important;
  }
}

@media (max-width: 992px) {
  .market-links-2 {
    display: none !important;
  }
}

@media (min-width: 1201px) {
  .market-links-2 {
    display: none !important;
  }
}